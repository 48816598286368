



























































































































































































































































































































































































.v-input{
  margin: 0
}
#chart {
  text-align: center;
  min-width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.modalidades {
  position: relative;
  background: #555;
  width: 150px;
  height: 150px;

}

